import './utils.css';
import Resume from '../assets/Resume.pdf'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, NavLink, useLocation } from 'react-router-dom';

export default function Navbar() {
    const [anchorElement, setAnchorElement] = useState(null)
    const open = Boolean(anchorElement)
    const [workActiveClass, setWorkActiveClass] = useState(" active")
    let location = useLocation()

    const handleClick = (event) => {
        setAnchorElement(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorElement(null)
    }

    useEffect(
        () => {
            if(location.hash === "#work")
            {
                setWorkActiveClass(" active")
            } else {
                setWorkActiveClass("")
            }

            document.querySelectorAll('.nav-link').forEach(
                (link) => {
                    if (link.href === window.location.href) {
                        link.setAttribute('aria-current', 'page')
                    }
                }
            )
        }, [location]
    )

    return (
        <header className="navbar" >
            <div className="home">
                <a href="/#intro" style={{ "textDecoration": "none" }} className='nav-link'>Beverly Vaz</a>
            </div>

            <ul role="menubar" className="menu-large" id="basic-menu">
                <li role="none" className={`menu-item${workActiveClass}`} data-menuanchor="work">
                    <a href="/#work" style={{ "textDecoration": "none" }} role="menuitem" className={`nav-link${workActiveClass}`}>
                        My Work
                    </a>
                </li>
                <li role="none" className="menu-item">
                    <NavLink to={Resume} target='_blank' rel="noreferrer" style={{ "textDecoration": "none" }} role="menuitem" className={({ isActive }) => (isActive ? 'navlink active' : 'inactive')}>
                        Resume
                    </NavLink>
                </li>
                <li role="none" className="menu-item">
                    <NavLink to={`/about`} style={{ "textDecoration": "none" }} role="menuitem" className={({ isActive }) => (isActive ? 'navlink active' : 'inactive')}>
                        About
                    </NavLink>
                </li>
            </ul>

            <div className="menu-small">
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    endIcon={<FontAwesomeIcon icon={faBars} />}
                >
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorElement}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        "aria-labelledby": 'basic-button'
                    }}
                >
                    <MenuItem onClick={() => {
                        handleClose()
                    }}><a href="/#work" style={{ "textDecoration": "none" }}>My Work</a></MenuItem>
                    <MenuItem onClick={() => {
                        handleClose()
                    }}><a href={Resume} target='_blank' rel="noreferrer" style={{ "textDecoration": "none" }}>Resume</a></MenuItem>
                    <MenuItem onClick={() => {
                        handleClose()
                    }}><Link style={{ "textDecoration": "none" }} to={`/about`}>About</Link></MenuItem>
                </Menu>
            </div>
        </header>
    )
}