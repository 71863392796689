import './utils.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEnvelope} from '@fortawesome/free-solid-svg-icons'
import {faCopyright} from '@fortawesome/free-regular-svg-icons'
import {faLinkedinIn, faMedium} from '@fortawesome/free-brands-svg-icons'

export default function Foot() {
    return(
        <footer className="footer">
          <div className="copyright">
            <FontAwesomeIcon icon={faCopyright}/> Beverly Vaz 2024
          </div>
          <div className="contact">
            <a href='mailto:beverlyavaz@gmail.com'><FontAwesomeIcon icon={faEnvelope} className="menu-item"/></a>
            <a href="https://www.linkedin.com/in/beverly-a-vaz/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedinIn} className="menu-item"/></a>
            <a href="https://medium.com/@beverlyavaz" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faMedium} className="menu-item"/></a>
          </div>
        </footer>
    )
}