import Grid from '@mui/material/Grid';

export default function MetaInfo({ metainfo }) {
    return (
        <>
            <h1>{metainfo.projectTitle}</h1>
            <h2>Overview</h2>
            <Grid container spacing={2} marginBottom="1rem">
                <Grid item xs={12} md={6} lg={4}>
                    <div style={{ "display": "flex", "flexDirection": "column" }}>
                        <div style={{"fontWeight": "800"}}>My role</div>
                        <div>{metainfo.role}</div>
                    </div>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                    <div style={{ "display": "flex", "flexDirection": "column" }}>
                        <div style={{"fontWeight": "800"}}>Team</div>
                        <div>{metainfo.team}</div>
                    </div>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                    <div style={{ "display": "flex", "flexDirection": "column" }}>
                        <div style={{"fontWeight": "800"}}>Duration & Location</div>
                        <div>{metainfo.duration_location}</div>
                    </div>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                    <div style={{ "display": "flex", "flexDirection": "column" }}>
                        <div style={{"fontWeight": "800"}}>Type</div>
                        <div>{metainfo.type}</div>
                    </div>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                    <div style={{ "display": "flex", "flexDirection": "column" }}>
                        <div style={{"fontWeight": "800"}}>Skills</div>
                        <div>{
                            <ul style={{"listStyle": "none", "padding": "0rem", "margin": "0rem"}}>
                                {
                                    metainfo.skills.map((skill, index) => {
                                        return <li key={index}>{skill}</li>
                                    })
                                }
                            </ul>
                        }</div>
                    </div>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                    <div style={{ "display": "flex", "flexDirection": "column" }}>
                        <div style={{"fontWeight": "800"}}>Tools</div>
                        <div>
                            {<ul style={{"listStyle": "none", "padding": "0rem", "margin": "0rem"}}>
                                {metainfo.tools.map((tool, index) => {
                                    return <li key={index}>{tool}</li>
                                })}
                            </ul>}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}