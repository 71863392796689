export default function TOC({toc, prefix}) {
    return (
        <div className="toc-toc">
            {
                toc.map((item, index) => {
                    return <a style={{"textDecoration": "none", "color": "#454ade"}} href={`${prefix}#${item.anchor}`} key={index}>{item.text}</a>
                })
            }
        </div>
    )
}