import ProjectLayout from "../utils/layout"
import MetaInfo from "../utils/metainfo"
import Cardlytics_background from "../../images/cardlytics/Cardlytics_background.png"
import Timeline from '../../images/cardlytics/Timeline.png'
import AffinityMap from '../../images/cardlytics/Affinity Map.jpg'
import Participants from '../../images/cardlytics/Participants.png'
import Findings from '../../images/cardlytics/Findings.png'
import ComparisonNeoBanks from '../../images/cardlytics/ComparisonNeoBanks.png'
import Archetype from '../../images/cardlytics/Archetype.jpg'
import CJM from '../../images/cardlytics/CJM.jpg'
import Divider from '@mui/material/Divider';
import "../utils/project.css"

export default function Cardlytics() {
    const metainfo = {
        role: "UX Researcher",
        team: "Myself",
        duration_location: "6 weeks, Atlanta",
        type: "Solo project; Foundational user research",
        skills: ["Market research", "Diary study", "Interviews", "Surveys", "Affinity Mapping", "Journey Mapping", "User Archetypes"],
        tools: ["UserInterviews", "Calendly", "Qualtrics", "Zoom", "Miro"],
        projectTitle: "Cardlytics: UXR"
    }

    const toc = [
        {
            text: "Problem Space",
            anchor: "problem_space"
        },
        {
            text: "Research Process",
            anchor: "research_process"
        },
        {
            text: "Analysis",
            anchor: "analysis"
        },
        {
            text: "Results",
            anchor: "results"
        },
        {
            text: "Impact",
            anchor: "impact"
        },
        {
            text: "Conclusion",
            anchor: "conclusion"
        }
    ]

    const background = "This was a project I worked on as a UX researcher at Cardlytics, a company that provides cash-back offers through traditional banks like Chase, and was part of my summer internship. \
    The project focused on understanding the motivations and behaviors of customers of neo-banks. A neo-bank is a bank created in the mid to late 2000s and has no physical branches."

    const body = 
    <div style={{ "display": "flex", "flexDirection": "column", "maxWidth": "80vw" }}>
        <h2 id="problem_space" className="project-section-title">Problem Space</h2>
        <div className="section-body">
            Neo-banks cater to a different customer segment than traditional banks, and since the company was beginning to partner with them, it became important to understand this customer segment.<br />
            The goals of this study were:
            <ul style={{ "marginTop": "0.25rem" }}>
                <li>to develop a customer profile of a neobank's customers</li>
                <li>to identify factors that influenced them to use a cash-back offer</li>
                <li>to identify the similarities and differences between them and the existing customer base of traditional banks</li>
            </ul>

            And the following problem statement emerged:
            <div style={{ "marginTop": "0.25rem", "fontWeight": "600", "paddingLeft": "1.5rem" }}>How might we improve the user experience of cash-back offers by catering it to neo-banking customers?</div>
        </div>
        <Divider/>

        <h2 id="research_process" className="project-section-title">Research Process</h2>
        <img src={Timeline} alt="Timeline of research methods" style={{ "maxWidth": "50rem" }} />
        <div className="section-body">
            I started the study with a literature review (also called market research) on neo-banks and their customers to gauge what the demographic was like and to understand their spending behavior. I looked at published articles and papers in academia and industry for this.
            I looked at 3 major neo-banks: Chime, Dave and Venmo. I chose to conduct the literature review prior to the diary study to gain a good overview of findings in this sector so far.<br /><br />

            Once I developed a sufficient understanding of this space, I proceeded with a diary study which was split into an entry interview, 2 surveys (1 per week) and an exit interview.
            I chose this method as it would allow me to observe customer behaviors and reflect on their purchase motivations and influences.
            The entry interview served as a way to set a baseline on a particapant's purchase patterns, the surveys served as checkpoints and the exit interview helped me obtain clarifications about their patterns of behavior as observed from the surveys.

            <h3 className="project-subsection-title">Target population</h3>
            <img src={Participants} className="project-writeup-image-50" alt="Bar chart of participant age groups vs number of participants"/>
            <div className="section-body">
                I had 12 participants take part in this study. Recruitment was carried out through the UserInterviews platform and participants were compensated for their time. They were located in the US, and were within the age group of 25-64 years (the age group was based on the literature review findings).
                These participants were neo-banking customers who actively used cash-back offers (at least 1 offer per week).
            </div>
        </div>
        <Divider/>

        <h2 id="analysis" className="project-section-title">Analysis</h2>
        <img src={AffinityMap} alt="Screebshot of affinity map made of sticky note groupings on a Miro board" style={{ "marginBottom": "2rem", "maxWidth": "60rem" }} />
        <div className="section-body">
            I conducted a thematic analysis by affinity mapping the data collected through interviews to identify patterns in what influenced participants to use cash-back offers.
            I used the survey data to verify the baseline of behaviors I had established through the entry interview and probed participants on any outliers that came up in the exit interviews.
            <br />
            As I organized my data into themes, I realized that they could be organized based on the various phases a customer goes through while using these offers, which led me to create a journey map as an artifact.
        </div>
        <Divider/>

        <h2 id="results" className="project-section-title">Results</h2>
        <div className="section-body">
            To set some context, the way cash-back offers work is, first the customer has to activate the offer on a particular card and then they have to use that card at the store to obtain the cash back.
            <h3 className="project-subsection-title">Key Findings</h3>

            <img src={Findings}  className="project-writeup-image-40" alt="List of motivations and frustrations of users found from research" />
            <h4  className="project-section-h4">Motivations</h4>
            The factors influencing a user's cash-back behavior are:
            <ul>
                <li>11 out of 12 participants indicated that they review offers before making a purchase</li>
                <li>8 out of 12 participants indicated that gaining cash-back pushes them closer to making a purchase</li>
                <li>Participants tend to search for offers with brands they shop often with or national brands</li>
                <li>Customers go through multiple cash-back platforms to find the best offer in terms of overall savings</li>
            </ul>

            <Divider>
                ||
            </Divider>

            <h4 className="project-section-h4">Frustrations</h4>
            The factors hindering a user's cash-back experience are:
            <ul>
                <li>Users forget to use the card they activated an offer on</li>
                <li>Nearly half of my participants indicated that offers provided on cash-back platforms were irrelevant or limited</li>
                <li>7 out of 12 of my participants indicated that a lot of terms and conditions on an offer, or unclear terms and conditions were a major pain point</li>
                <li>Half of the participants indicated dissatisfaction with the current UX of scrolling through a long list of offers</li>
            </ul>

            <Divider>
                ||
            </Divider>

            <h4  className="project-section-h4">Customer profile</h4>
            <img src={ComparisonNeoBanks} className="project-writeup-image-60" alt="Side-by-side comparison of customer profiles of Chime, Venmo and Dave" style={{"marginBottom": "1rem" }} />
            Based on the literature review, I created a table comparing the 3 neo-banks and saw some similarties and differences across them, which helped me address the goal of creating a customer profile:
            <ul style={{"marginBottom": "3rem"}}>
                <li>Millenials tend to be a significant proportion of the customer base of neo-banks</li>
                <li>The customer profile of each neo-bank differs from the other in terms of educational levels and income brackets of customers</li>
            </ul>

            <Divider/>

            <h3 className="project-subsection-title">Artifacts</h3>
            <h4 className="project-section-h4">User journey map</h4>
            From the analysis of data collected through the diary study, there emerged 6 stages a user progresses through on their cash-back journey. A closer look at these stages helped me answer my goal of understanding what
            influenced a user to utilize cash-back offers.
            <img src={CJM} className="project-writeup-image-60" alt="User journey map based on research findings" style={{"marginBottom": "1rem", "marginTop": "1rem" }} />
            <ol>
                <li style={{ "marginBottom": "2rem" }}>
                    <span style={{ "fontWeight": "600" }}><mark>Awareness:</mark></span> The user understands the necessity to look at offers <br />
                    {/* At this stage users learn about offers through e-mails and push notifications and are planning to make big purchases and would like to save on the purchase.<br/> */}
                    <span style={{
                        "textDecoration": "underline"
                    }}>Pain point:</span>This stage was a pain point for users as there was no way through which they could learn about new, relevant offers, causing them to miss out on a lot of offers they would have liked to use.
                </li>

                <li style={{ "marginBottom": "2rem" }}>
                    <span style={{ "fontWeight": "600" }}><mark>Comprehension:</mark></span> The user looks at offers<br />
                    {/* The user looks at offers through a site or mobile app because they receive a push-notification or e-mail about it, or to find an offer for a purchase they want to make.<br/> */}
                    <span style={{
                        "textDecoration": "underline"
                    }}>Pain point:</span>Scrolling through a long list of offers makes for a poor user experience.
                </li>

                <li style={{ "marginBottom": "2rem" }}>
                    <span style={{ "fontWeight": "600" }}><mark>Consideration:</mark></span> The user chooses an offer<br />
                    {/* The user looks at the criteria to redeem an offer and the amount of cash-back they earn to decide which one is the best to go with.<br/> */}
                    <span style={{
                        "textDecoration": "underline"
                    }}>Pain point:</span>The terms and conditions on an offer are confusing
                </li>

                <li style={{ "marginBottom": "2rem" }}>
                    <span style={{ "fontWeight": "600" }}>Activation:</span> The user activates an offer<br />
                    {/* The user activates the offer on the site or mobile app. */}
                </li>

                <li style={{ "marginBottom": "2rem" }}>
                    <span style={{ "fontWeight": "600" }}><mark>Redemption:</mark></span> The user redeems an offer<br />
                    {/* The user redeems the offer through in-person or online shopping.<br/> */}
                    <span style={{
                        "textDecoration": "underline"
                    }}>Pain point:</span>Customers forget to redeem an offer while making a purchase, either because they do not realize they had an offer available or they used the wrong credit/debit card.
                </li>

                <li style={{ "marginBottom": "2rem" }}>
                    <span style={{ "fontWeight": "600" }}>Post-redemption:</span> Follow-up actions after the user redeems an offer<br />
                    {/* The user receives their cash-back amount through their card statements and can use it to make more purchases. */}
                </li>
            </ol>

            <Divider>
                ||
            </Divider>
            
            <h4 className="project-section-h4">User archetypes</h4>
            I further created a matrix comparing the neo-banking participants to traditional banking participants (from a different study conducted at Cardlytics) along their shopping approach and effort to save in a bid to create user archetypes.

            <img src={Archetype} className="project-writeup-image-50" alt="XY Graph plot of users based on their shopping approach vs effort to save" style={{"marginBottom": "1rem", "marginTop": "1rem" }} />

            Here, the shopping approach is along a spectrum of being  a list shopper (someone who pre-plans their purchases) to an impulsive shopper (someone who doesn't pre-plan their purchases), and, <br />
            the effort to save is along a spectrum of high effort (where a user spends a lot of time looking for the best cash-back offer) to low effort (where a user doesn't spend a lot of time looking for the best offer).<br /><br />

            This helped me address my goal of noting the similarities and differences across these key consumer groups, which are:
            <ul>
                <li>Both groups are largely list shoppers who compare offers across multiple platforms</li>
                <li>More neo-banking participants are high-effort impulse shoppers than the traditional banking participants</li>
            </ul>
        </div>
        <Divider/>

        <h2 id="impact" className="project-section-title">Impact</h2>
        <div className="section-body">
            To improve the user experience and engage users in the cash-back process, I proposed the following design directions to the team.
            <div style={{
                "display": "grid",
                "gridTemplateColumns": "5fr .5fr 5fr",
                "marginTop": "2rem"
            }}>
                <div style={{"fontWeight": "600", "borderBottom": ".25rem black solid", "marginBottom": ".25rem"}}>Pain Point</div>
                <div></div>
                <div style={{"fontWeight": "600", "borderBottom": ".25rem black solid", "marginBottom": ".25rem"}}>Design Direction</div>

                <div style={{"marginBottom": "1rem"}}>Customers forget to redeem an offer while making a purchase</div>
                <div></div>
                <div style={{"marginBottom": "1rem"}}>Just-in-time notifications about activated offers for regular purchases</div>
                
                <div style={{"marginBottom": "1rem"}}>Unclear terms and conditions</div>
                <div></div>
                <div style={{"marginBottom": "1rem"}}>Standardized offer constructs, like cash-back in dollar amounts than percentages</div>

                <div style={{"marginBottom": "1rem"}}>Scrolling through a long list of offers</div>
                <div></div>
                <div style={{"marginBottom": "1rem"}}>Search functionality to find offers more easily</div>

                <div style={{"marginBottom": "1rem"}}>Irrelevant or limited selection of offers</div>
                <div></div>
                <div style={{"marginBottom": "1rem"}}>Provide offers based on geo-location or with national brands</div>

                <div style={{"marginBottom": "1rem"}}>Users don't learn about new, relevant offers</div>
                <div></div>
                <div style={{"marginBottom": "1rem"}}>Have a consistent release schedule of offers and inform users about it</div>
            </div>
        </div>
        <Divider/>

        <h2 id="conclusion" className="project-section-title">Conclusion</h2>
        <div className="section-body">
            I am so grateful to have had the opportunity to learn so many valuable lessons through this internship experience.<br/> 
            I learnt how to ensure my stakeholders felt like they were heard, and to budget for more participants than I would require in the event that people drop out of my study. 
            This was such a great experience as I learnt how research is done at a company, the teams UX cross-collaborates with and how best to communicate what you find back to the people to whom it matters the most, 
            which I continue to apply in the work I do.
        </div>
    </div>

    return (
        <>
            <img src={Cardlytics_background} alt="A mobile screen of cash-back offers and the text describing it is: Cardlytics UX research internship" style={{ "borderRadius": "0rem 4rem 4rem 0rem" }} />
            <MetaInfo metainfo={metainfo} />
            <ProjectLayout tableOfContents={toc} prefix="/cardlytics_uxr" background={background} body={body} nextProject={{name: "MaxMap", url: "maxmap"}}/>
        </>
    )
}