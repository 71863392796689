import Navbar from '../utils/navbar';
import Foot from '../utils/foot';

export default function Project ({content}) {
    return (
        <>
        <Navbar/>
        <div className='project-content'>
            {content}
        </div>
        <Foot/>
        </>
    )
}