import {faCaretDown} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

export default function Background({ background }) {
    return (
        <Accordion style={{"width": "90vw"}}>
            <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                aria-controls="background-content"
                id="background-header"
            >
                <h2>Background</h2>
            </AccordionSummary>
            <AccordionDetails className='section-body'>
                {background}
            </AccordionDetails>
        </Accordion>
    )
}