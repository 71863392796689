import ProjectLayout from "../utils/layout"
import Divider from '@mui/material/Divider';
import MetaInfo from "../utils/metainfo"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCircleExclamation, faEye, faBook, faScaleBalanced, faMagnifyingGlass, faSwatchbook, faComputerMouse, faUsersViewfinder, faHeart} from "@fortawesome/free-solid-svg-icons"
import { faFaceSmile } from "@fortawesome/free-regular-svg-icons"
import MaxMap_background from "../../images/maxmap/MaxMap_background.png"
import Persona from "../../images/maxmap/Persona.png"
import Analysis from "../../images/maxmap/Analysis.png"
import Findings from "../../images/maxmap/Findings.png"
import CallFlow from "../../images/maxmap/CallFlow.png"
import Storyboard from "../../images/maxmap/Storyboard.png"
import HighFidelity from "../../images/maxmap/HighFidelity.png"

export default function MaxMap() {
    const metainfo = {
        role: "UX Researcher & Designer",
        team: "4 member team",
        duration_location: "4 months, Atlanta",
        type: "Course project",
        skills: ["Interviews", "Surveys", "Competitive Analysis", "Role play", "Personas", "Empathy maps", "Storyboarding",
            "Affinity Mapping", "Call flows", "Lo-fi/Hi-fi prototyping", "Heuristic evaluation", "Voice Usability Scale"],
        tools: ["Google Forms", "Voiceflow", "Miro", "Figma", "Trello", "Calendly", "Virtual Conferencing Tool (MS Teams)"],
        projectTitle: "MaxMap"
    }

    const toc = [
        {
            text: "Problem Space",
            anchor: "problem_space"
        },
        {
            text: "Research Process",
            anchor: "research_process"
        },
        {
            text: "Design Process",
            anchor: "design_process"
        },
        {
            text: "Evaluation",
            anchor: "evaluation"
        },
        {
            text: "Conclusion",
            anchor: "conclusion"
        }
    ]

    const background = "Maps are visual by nature. So, how do people, who struggle to see, experience and interact with the tacit nature of maps? \
    In this project, I researched and designed a digital navigational tool for people with low vision, which included accessible features that helped this user group overcome \
    challenges they usually face with popular navigational applications."

    const body = <div style={{ "display": "flex", "flexDirection": "column", "maxWidth": "80vw" }}>
        <h2 id="problem_space" className="project-section-title">Problem Space</h2>
        <div className="section-body">
            With digital maps being a visual medium, it affects the manner in which people with visual impairments make use of them and the extent to which they can rely on maps for navigation.<br />
            With that in mind, in this project I tried to address the following goals:
            <ul style={{ "marginTop": "0.25rem" }}>
                <li>How do people with low vision interact with digital maps before and during their journey?</li>
                <li>What is the current understanding of maps and the navigational habits of these users?</li>
                <li>What essential features/information should digital maps contain for this population?</li>
            </ul>

            This led to the following problem statement for this project:
            <div style={{ "marginTop": "0.25rem", "fontWeight": "600", "paddingLeft": "1.5rem" }}>How might I design a digital navigational tool for people with low vision so that it increases their independence to move about?</div>
        </div>
        <Divider />
        <h2 id="research_process" className="project-section-title">Research Process</h2>
        <div className="section-body">
            To answer the project goals and problem statement, I first conducted a literature review to develop an understanding of existing research in this area and to shape the questions we wanted answered moving forward.<br />
            I then conducted interviews and ran a survey to gather responses from a larger section of the population to understand the problem space from their perspective.
            This was further supplemented with a roleplay activity where I tested out 3 competitor applications that catered to the needs of this population.

            <h3 className="project-subsection-title">Target population</h3>
            <img src={Persona} className="project-writeup-image-50" alt="Persona & empathy map of target user" style={{"marginBottom": "2rem" }} />
            I spoke to 13 participants during the interview phase, who were members of a local senior citizen's home or acquaintances of personal connection. The age of these particpants was 60 years and above.<br />
            I received 26 responses to our survey, with 41% of respondents being above 60 years of age.

            <Divider style={{ "marginTop": "2rem" }}>
                ||
            </Divider>

            <h3 className="project-subsection-title">Analysis & Findings</h3>
            <img src={Analysis} className="project-writeup-image-50" alt="Affinity map of findings" style={{"marginBottom": "2rem" }} />
            The data collected through the interviews, surveys and roleplay activities helped craft the persona and empathy map artifacts.<br />
            To analyze the data collected through the interviews and surveys, I used an affinity diagram to gain insights into user behaviors and expectations.
            The survey data was used to statistically quantify a user's information needs.
            <img src={Findings} className="project-writeup-image-50"  alt="Bar chart of information needs of participants" style={{"marginBottom": "2rem" }} />

            This led me to the following findings:
            <div style={{
                "display": "grid",
                "gridTemplateColumns": "5fr .5fr 5fr",
                "marginTop": "2rem"
            }}>
                <div style={{ "fontWeight": "600", "borderBottom": ".25rem black solid", "marginBottom": ".25rem" }}>Key Questions</div>
                <div></div>
                <div style={{ "fontWeight": "600", "borderBottom": ".25rem black solid", "marginBottom": ".25rem" }}>Findings</div>

                <div style={{ "marginBottom": "1rem" }}>How do people with low vision interact with digital maps before and during their journey?</div>
                <div></div>
                <div style={{ "marginBottom": "1rem" }}>Pre-planning is important but not currently supported by digital maps</div>

                <div style={{ "marginBottom": "1rem" }}>What is the current understanding of maps of these users?</div>
                <div></div>
                <div style={{ "marginBottom": "1rem" }}>Participants rely on voice assistants to use digital maps</div>

                <div style={{ "marginBottom": "1rem" }}>What essential features/information should digital maps contain?</div>
                <div></div>
                <div style={{ "marginBottom": "1rem" }}>Context in which the map is used should be considered</div>
            </div>
        </div>

        <Divider />
        <h2 id="design_process" className="project-section-title">Design Process</h2>
        <div className="section-body">
            I conducted a brainstorming session to come up with ideas for the top 4 issues that emerged from the findings. This led to the following design features:
            <div style={{
                "display": "grid",
                "gridTemplateColumns": "5fr .5fr 5fr",
                "marginTop": "2rem"
            }}>
                <div style={{ "fontWeight": "600", "borderBottom": ".25rem black solid", "marginBottom": ".25rem" }}>Issues or Needs</div>
                <div></div>
                <div style={{ "fontWeight": "600", "borderBottom": ".25rem black solid", "marginBottom": ".25rem" }}>Design Feature</div>

                <div style={{ "marginBottom": "1rem" }}>I want my map to be personalized</div>
                <div></div>
                <div style={{ "marginBottom": "1rem" }}>An <span style={{ "fontWeight": "600" }}>onboarding survey</span> to personalize the voice assistant</div>

                <div style={{ "marginBottom": "1rem" }}>I want detailed information about my surroundings</div>
                <div></div>
                <div style={{ "marginBottom": "1rem" }}><span style={{ "fontWeight": "600" }}>Sound labels:</span> A unique tone attached to landmarks<br /><span style={{ "fontWeight": "600" }}>3D sound:</span> The pitch and tone of sound provides directionality and distance</div>

                <div style={{ "marginBottom": "1rem" }}>I want realtime information about my surroundings</div>
                <div></div>
                <div style={{ "marginBottom": "1rem" }}><span style={{ "fontWeight": "600" }}>Crowdsourcing</span></div>
            </div>

            <Divider style={{ "marginTop": "2rem" }}>
                ||
            </Divider>

            <h3 className="project-subsection-title">Prototyping & Evaluation</h3>
            I conducted 2 rounds of prototyping and evaluation. For the first round I used sketches for low-fidelity prototyping and a storyboard to demonstrated the product.<br/>
            For the second round of high-fidelity prototyping I used call flows to demonstrated the interface.

            <h4 className="project-section-h4">Low-fidelity prototyping</h4>
            I did two rounds of evaluations for the low-fidelity prototype. For the <span style={{ "fontWeight": "600" }}>first round</span>, I used a Wizard-of-Oz scenario walkthrough and interviewed 2 participants to gauge how receptive they were to the ideas.
            These sessions were conducted virtually and with people with low vision.<br/>
            <img src={Storyboard} alt="Sketched comic strip demonstrating the sound label feature" style={{ "maxWidth": "80vw", "margin": "2rem 0rem" }} />
            
            Based on the analysis from the evaluations, I discovered the following:
            <ul style={{ "marginTop": "0.25rem" }}>
                <li>Participants want more control and personalization. So, a settings feature that enables users to do so needed to be created.</li>
                <li>A participant pointed out that the 3D sound feature requires participants to listen to the app with both their ears, so they would not be able to listen to what is going on around them. So, this feature needed to be dropped.</li>
            </ul>

            <Divider style={{ "margin": "2rem 0rem" }}>
                ||
            </Divider>
            
            For the <span style={{ "fontWeight": "600" }}>second round</span>, I got rid of the 3D sound feature and interviewed 2 participants. I used call flows to direct the scenarios during which I pretended to be the voice assistant and responded to the participants commands based on the call flow.
            One participant was sighted and an expert in the area of design, and the other had low vision. These sessions were conducted virtually and helped us test the usabilitiy and functionality of our prototype.
            <img src={CallFlow} className="project-writeup-image-50"  alt="Chart of boxes and arrows showcasing call flow of sound label feature" style={{"margin": "2rem 0rem" }} />
            
            I discovered the following findings from these sessions:
            <ul style={{ "marginTop": "0.25rem" }}>
                <li>Participants felt overwhelmed by the number of options and steps they had to take. So, I had to rework how the information was presented through a voice assistant.</li>
                <li>Participants felt that there wasn't an easy way to recover from errors and this contributed to them feeling like there was a big learning curve. A way needed to be provided to help users understand how to interact with the system.</li>
            </ul>

            <Divider style={{ "margin": "2rem 0rem" }}>
                ||
            </Divider>

            <h4 className="project-section-h4">High-fidelity prototyping</h4>
            For the high-fidelity prototype, I chose VoiceFlow, a platform that allowed mock voice assistant capabilities, which would allow participants to have a more realistic experience of the end product.<br/>
            The high fidelity prototype consisted of the 3 features I settled on from the low-fidelity evaluations. 
            Moreover, I decided to name the assistant <span style={{ "fontWeight": "600" }}>Maxi</span> as it was short, yet it had enough syllables to be identified correctly, making it easier to interact with the prototype.
            I also created a version of a map that could be used in the prototype and called this MaxMap.
            <img src={HighFidelity} alt="Flow chart of commands and images for VoiceFlow prototype" style={{ "maxWidth": "80vw", "margin": "2rem 0rem 0rem" }} />
        </div>
        <Divider />
        <h2 id="evaluation" className="project-section-title">Evaluation</h2>
        <div className="section-body">
            For the final set of evaluations using the high-fidelity prototype, I used a mix of user and expert evaluations.<br/>
            <h3 className="project-subsection-title">User Evaluations</h3>
            For the user evaluations, each session had a moderated task for each feature. The order of tasks was counterbalanced in sessions to ensure there was no bias. 
            However, the first feature tested was always the onboarding survey as it introduced participants to the product. I had 4 users evaluate the prototype through virtual sessions.<br/>
            During the usability session, I asked a few follow up questions after each participant completed a task and had the participant fill out a voice usability scale survey at the end of the session.

            <div style={{
                "display": "grid",
                "gridTemplateColumns": "5fr .5fr 5fr",
                "marginTop": "2rem"
            }}>
                <div style={{ "fontWeight": "600", "borderBottom": ".25rem black solid", "marginBottom": ".25rem" }}>Task</div>
                <div></div>
                <div style={{ "fontWeight": "600", "borderBottom": ".25rem black solid", "marginBottom": ".25rem" }}>Description</div>

                <div style={{ "marginBottom": "1rem" }}><span style={{ "fontWeight": "600" }}>Personalize the voice assistant</span><br/>
                Feature: Onboarding survey
                </div>
                <div></div>
                <div style={{ "marginBottom": "1rem" }}>Set up the voice assistant to your preferences by going through the survey</div>

                <div style={{ "marginBottom": "1rem" }}><span style={{ "fontWeight": "600" }}>Get more information on the surrounding</span><br/>
                Feature: Sound labels
                </div>
                <div></div>
                <div style={{ "marginBottom": "1rem" }}>Navigate to a location and interact with the sound labels when they appear to obtain more information on your surroundings</div>

                <div style={{ "marginBottom": "1rem" }}><span style={{ "fontWeight": "600" }}>Place and edit a pin</span><br/>
                Feature: Crowdsourcing
                </div>
                <div></div>
                <div style={{ "marginBottom": "1rem" }}>Create and edit a pin on the map to provide more information to other users</div>
            </div>

            <Divider style={{ "margin": "2rem 0rem" }}>
                ||
            </Divider>

            <h3 className="project-subsection-title">Expert Evaluations</h3>
            For the expert evaluations, I had experts do a heuristic evaluation with a think-aloud protocol. I had 3 experts evaluate the prototype.
            These participants were presented with the above mentioned moderated tasks during a virtual meeting, so they could explore the prototype, and conducted the evaluations in their own time.
            The heuristics experts evaluated the prototype on are:
            <div style={{
                "display": "grid",
                "gridTemplateColumns": "5fr .5fr 5fr",
                "marginTop": "2rem"
            }}>
                <div style={{ "fontWeight": "600", "borderBottom": ".05rem black solid", "padding": "0rem 0rem 1rem", "display": "flex", "flexDirection": "row", "gap": "1rem" }}><FontAwesomeIcon icon={faEye}/>Visibility of system status</div>
                <div></div>
                <div style={{ "fontWeight": "600", "borderBottom": ".05rem black solid", "padding": "0rem 0rem 1rem", "display": "flex", "flexDirection": "row", "gap": "1rem" }}><FontAwesomeIcon icon={faCircleExclamation}/>Error prevention</div>

                <div style={{ "fontWeight": "600", "borderBottom": ".05rem black solid", "padding": "1rem 0rem", "display": "flex", "flexDirection": "row", "gap": "1rem" }}><FontAwesomeIcon icon={faScaleBalanced}/>Match between system and real world</div>
                <div></div>
                <div style={{ "fontWeight": "600", "borderBottom": ".05rem black solid", "padding": "1rem 0rem", "display": "flex", "flexDirection": "row", "gap": "1rem" }}><FontAwesomeIcon icon={faComputerMouse}/>User control and freedom</div>

                <div style={{ "fontWeight": "600", "borderBottom": ".05rem black solid", "padding": "1rem 0rem", "display": "flex", "flexDirection": "row", "gap": "1rem" }}><FontAwesomeIcon icon={faBook}/>Consistency and standards</div>
                <div></div>
                <div style={{ "fontWeight": "600", "borderBottom": ".05rem black solid", "padding": "1rem 0rem", "display": "flex", "flexDirection": "row", "gap": "1rem" }}><FontAwesomeIcon icon={faUsersViewfinder}/>Focus on the user</div>

                <div style={{ "fontWeight": "600", "borderBottom": ".05rem black solid", "padding": "1rem 0rem", "display": "flex", "flexDirection": "row", "gap": "1rem" }}><FontAwesomeIcon icon={faMagnifyingGlass}/>Recognition than recall</div>
                <div></div>
                <div style={{ "fontWeight": "600", "borderBottom": ".05rem black solid", "padding": "1rem 0rem", "display": "flex", "flexDirection": "row", "gap": "1rem" }}><FontAwesomeIcon icon={faFaceSmile}/>Casual and friendly</div>

                <div style={{ "fontWeight": "600", "padding": "1rem 0rem", "display": "flex", "flexDirection": "row", "gap": "1rem" }}><FontAwesomeIcon icon={faSwatchbook}/>Aesthetic and minimalist design</div>
                <div></div>
                <div style={{ "fontWeight": "600", "padding": "1rem 0rem", "display": "flex", "flexDirection": "row", "gap": "1rem" }}><FontAwesomeIcon icon={faHeart}/>Trustworthy</div>
            </div>

            <Divider style={{ "margin": "2rem 0rem" }}>
                ||
            </Divider>

            <h3 className="project-subsection-title">Findings</h3>
            From the <span style={{ "fontWeight": "600" }}>user evaluations</span>, the prototypes received an overall usability score of 51.46, indicating a need for improvement. The major findings were:
            <ul style={{ "marginTop": "0.25rem" }}>
                <li>Participants were not familiar with the concept of crowdsourcing and hence were confused by the feature.</li>
                <li>The learning curve associated with using the voice assistant hampered the experience participants had.</li>
                <li>The sound label feature was positively received with participants requesting a release of the feature.</li>
            </ul>

            From the <span style={{ "fontWeight": "600" }}>expert evaluations</span>, the top 3 areas that were of concern were:
            <ul style={{ "marginTop": "0.25rem" }}>
                <li>Error prevention: The system wasn't transparent with how it dealt with errors</li>
                <li>User control and freedom: The voice assistant did not accurately identify user input</li>
                <li>Trustworthiness: The lack of transparency and inability to correctly receive input made participants distrust the tool</li>
            </ul>
        </div>

        <Divider />
        
        <h2 id="conclusion" className="project-section-title">Conclusion</h2>
        <div className="section-body">
            I am glad to have had the opportunity to work on an accessible solution for people with disabilities and to have tried to design a voice assistant, through this project.<br/>
            This project taught me that when it comes to designing and testing accessible solutions, I need to be flexible with the mediums I use to test, and check with my participants, since a video call may not work for everyone.
            Moreover, a key lesson that I learnt from this project was to have a buffer between phases in my project timeline to plan for unexpected events. I am excited to take the learnings I obtained from this project and use it in my future work to build more inclusive experiences.
        </div>
    </div>

    return (
        <>
            <img src={MaxMap_background} alt="A mobile screen of the MaxMap application and text describing it is: MaxMap Navigation For All"
                style={{ "borderRadius": "0rem 4rem 4rem 0rem", "width": "100%", "height": "auto" }} />
            <MetaInfo metainfo={metainfo} />
            <ProjectLayout tableOfContents={toc} prefix="/maxmap" background={background} body={body} nextProject={{ name: "MyGrocery", url: "mygrocery" }} />
        </>
    )
}