import { useRouteError } from "react-router-dom";
import Navbar from '../utils/navbar';
import Foot from '../utils/foot';

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <>
      <Navbar />
      <div className='content'>
        <div id="error-page">
          <h1>Oops!</h1>
          <p>Sorry, an unexpected error has occurred.</p>
          <p>
            <i>{error.statusText || error.message}</i>
          </p>
        </div>
      </div>
      <Foot />
    </>
  );
}