import Cardlytics from '../images/projects/Cardlytics.png'
import MaxMap from '../images/projects/MaxMap.png'
import MyGrocery from '../images/projects/MyGrocery.png'

export const projects = [
    {
        title: "Cardlytics: UXR",
        role: "UX Researcher",
        src: Cardlytics,
        altText: "Mobile screen with cash-back offers",
        description: "Discover insights into the purchase behaviors of neo-banking customers",
        link: "/cardlytics_uxr"
    },

    // {
    //     title: "Accessible accommodations",
    //     role: "UX Researcher • Designer • Developer",
    //     src: Cardlytics,
    //     altText: "Graduate capstone project",
    //     description: "Explore a way to revolutionize student accommodations"
    // },

    {
        title: "MaxMap",
        role: "UX Researcher • Designer",
        src: MaxMap,
        altText: "Two mobile screens with the MaxMap application open on them",
        description: "Create accessible maps for the visually impaired",
        link: "/maxmap"
    },

    {
        title: "MyGrocery",
        role: "UX Researcher • Designer • Developer",
        src: MyGrocery,
        altText: "Three mobile screens with the MyGrocery application open on them",
        description: "Practice sustainable food waste management",
        link: "/mygrocery"
    }
]